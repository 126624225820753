import * as React from "react"
import { Link } from "gatsby"
import Layout from "components/Layout"

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <div className="flex flex-col px-4 py-6 text-center">
        <h1 className="pb-4 text-3xl font-black text-tecsight-blue">Not Found</h1>
        <p className="text-gray-600">
          The page you were looking for was not found.
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
